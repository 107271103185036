import { h, defineComponent, computed } from 'vue';
import { router, usePage } from '@inertiajs/vue3';

export default {
  install(app) {
    app.component('RouterLink', defineComponent({
      props: {
        to: {
          type: String,
          required: true,
        },
      },
      setup(props, { slots }) {
        const href = props.to;
        const currentUrl = computed(() => usePage().url);

        const route = computed(() => ({ href }));
        const isActive = computed(() => currentUrl.value.startsWith(href));
        const isExactActive = computed(() => href === currentUrl.value);

        function navigate(e) {
          if (e.shiftKey || e.metaKey || e.ctrlKey) return;
          e.preventDefault();
          router.visit(href);
        }

        return () => h('a', {
          href: route.value.href,
          onClick: navigate,
          class: {
            'is-active': isActive.value,
            'is-exact-active': isExactActive.value,
          },
        }, slots.default());
      },
    }));
  },
};
